a:hover{
  color: white !important;
  text-decoration: none;
}

h1 { font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace; font-size: 28px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 23px; } 
h3 { font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 23px; } 
h4 { font-variant: normal; font-weight: 700; line-height: 23px; margin-bottom: 2vh;}
h5 { font-variant: normal; font-weight: 700; line-height: 23px; margin-bottom: 1vh;}

@import 'https://fonts.googleapis.com/css?family=Roboto';
html, body {
	background:  #171d22;
  background-size: cover;
}

p>a {
	color: #fd084a;
}
.blink {
  animation: blink 0.5s infinite;
}
@keyframes blink{
  to { opacity: .0; }
}
.flex {
	display: flex;
}

.typewriter {
  color: #b7c4cd;
  text-align: left;
}

.navbar-button {
  background-color: transparent;
  margin-left: 8px;
  margin-right:8px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 2vh;
  padding-right: 2vh;
  color: white;
  font-weight: normal;
  text-align: center;
  font-size: large;
  box-sizing: content-box;
  white-space: nowrap;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
}

.navbar-button:hover {
  opacity: 0.7;
}

.App-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  height: 100%;
  flex-flow: column;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.text-name{
  margin-top: 1vh;
  margin-bottom: 4vh;
  font-size: 5vmin;
  color: whitesmoke;
  text-align: left;
}

.name{
  background-image: linear-gradient(to bottom right, rgb(255, 0, 255), rgb(16, 202, 241));
  background-clip: text;
  color: transparent;
  text-align: left;
}

.project-type-heading {
  padding: 2vh, 0vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 23px; 
  background-image: linear-gradient(to bottom right, rgb(255, 0, 255), rgb(16, 202, 241));
  background-clip: text;
  color: transparent;
  text-align: left;
}

.text-box-about-me{
  font-size: medium;
  width: 70%;
  overflow: hidden;
  margin-bottom: 5vh;
  background-color: transparent;
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: whitesmoke;
}

.about-me-summary{
  margin-top: 1vh;
  margin-bottom: 4vh;
  font-size: 5vmin;
  color: whitesmoke;
  text-align: left;
}

.text-box{
  font-size: medium;
  align-self: center;
  width: 100%;
  overflow: hidden;
  padding: 5vh 10vw;
  margin-top: 2vh;
  background-color: #20292f;
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: whitesmoke;
  border-radius: 1vh;
}

.padding-image{
  background-color: transparent;
  padding: 5vh 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.text-box-MM{
  position: relative;
  background: transparent;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 5vh 10vw;
  margin: 1vh 0vh;
  text-align: left;
  font-size: medium;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  border-radius: 1vh;
}

.App-MM-mobileView{
  display: none;
}

.card-title{
  font-style: normal; font-variant: normal; font-weight: 700; line-height: 23px;
  margin-bottom: 2vh;
  background-image: linear-gradient(to bottom right, rgb(255, 0, 255), rgb(16, 202, 241));
  background-clip: text;
  color: transparent;
  text-align: left;
}

.card-title-MM {
  text-align: left;
  font-weight: bold;
}

.projects-list{
  height: 50vh;
  width: 100%;
  background-color: transparent;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
}

.projects-list::-webkit-scrollbar{
  display: none;
}

.project-box{
  display: inline-block;
  background-color: #20292f;
  color: lightslategray;
  border-radius: 1vh;
  word-break: break-word;
  text-align: justify;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-picture{
  height: 15em;
  width: 10em;
  overflow: hidden;
  border-radius: 1vh 1vh 0px 0px;
  pointer-events: none;
}

.link{
  color: #b7c4cd;
}

a:hover{
  opacity: 0.6 !important;
  text-decoration: none;
}

.row-group{
  width: fit-content;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}

.column-group{
  margin-top: 15vh;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

/* Images and Icons */
.App-avatar{
  display:inline-block;
  vertical-align: baseline;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 5vh;
}

.App-MM{
  height: 30vmin;
  margin-left: 5vw;
  pointer-events: none;
}

.text-and-img-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.card-section{
  width: 100%;
  height: 100%;
  padding: 8vw;
}

.App-navbar {
  padding: 2vh 8vw;
  position: relative;
  background-color: transparent;
  min-height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
  overflow: hidden;
}


.App-footer {
  background-color: #1b2328;
  min-height: 15vh;
  color: slategray;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.socialButtons{
  margin-top: 5vh;
  font-size: larger;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.fa {
  padding-top: 1vh;
  padding-right: 2vh;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  color: white;
}

.skills-icon{
  font-size:large;
  height: 12vh;
  width: 15vw;
  text-align: center;
}

.fa:hover {
  opacity: 0.7;
}

.fa-phone{
  color: darkslategray;
}

.fa-envelope{
  color: darkslategray;
}

.mobiles-image{
  position: relative;
  height: 50vh;
  z-index: 0;
}

/*CSS Setup for mobile devices of width < 1080 */
@media only screen and (max-width: 1080px) {

  .row-group{
    width: 100%;
    justify-content:space-around;
  }

  .card-section{
    padding: 0vw;
  }

  .App-body{
    padding: 0px;
  }
  .welcome-container{
    padding: 0px;
  }

  .App-MM {
      display: none;
  }

  .padding-image{
    display: none;
  }

  .App-MM-mobileView{
      display: block;
      height: 25vh;
      margin-bottom: 4vh;
      position: absolute;
      left: 50%;              
      transform: translate(-50%, 0);
      pointer-events: none;
  }
  .card-title-MM{
    display: none;
    text-align: left;
    font-weight: bold;
  }
  .text-and-img-row{
    margin-top: 25vh;
  }
  
  .App-avatar{
    display: none;
  }

  .welcome-container{
    background-color: transparent;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .text-name {
    font-size: x-large;
    margin-bottom: 2vh;
  }
  
  .typewriter {
    font-size: medium;
  }

  .socialButtons{
    font-size: large;
  }

  .skills-icon{
    font-size: 0.6em;
    text-align: center;
 
  }
  
  .App-navbar{
    padding: 2vh 20vw;
    justify-content: center;
  }
  
  .navbar-button{
    font-size: medium;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .about-me-summary{
    margin-top: 1vh;
    margin-bottom: 6vh;
    font-size: 5vmin;
    color: whitesmoke;
    text-align: left;
  }

  .text-box-about-me{
    width: 100%;
    margin-top: 5vh;
    padding: 5vh 10vw;
  }

  .text-box {
    border-radius: 0%;
  }

}

section{
  width: 100%;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .row-group {
    animation: App-fade 2s forwards;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-fade{
  from {   
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
